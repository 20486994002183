<template>
  <div class="dry-power">
    <moduleTitle title="仓储数据"></moduleTitle>
    <div class="dry-power-content">
      <div class="cangchu">仓储统计</div>
      <div class="cangchuDown">
        <div class="dowmNEI">
          <div class="neiText">
            <div class="txtT">存储能力</div>
            <div class="txtD">{{ 1000 }}吨</div>
          </div>
          <div class="neiText">
            <div class="txtT">剩余能力</div>
            <div class="txtD">{{ 1000 }}吨</div>
          </div>
          <div class="neiText">
            <div class="txtT">当日入库量</div>
            <div class="txtD">{{ 19 - 1 }}吨</div>
          </div>
          <div class="neiText">
            <div class="txtT">当日出库量</div>
            <div class="txtD">{{ 10 - 1 }}吨</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
export default {
  components: {
    moduleTitle
  },
  props: {

  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(455vw);
  height: companyH(292vh);
  background: rgba(0, 0, 0, 0.7);
  border: companyW(1vw) solid;
  z-index: 99;

  /* opacity: 0.74; */
  .dry-power-content {
    margin-top: companyH(40vh);

    /* padding-top: companyH(5vh); */

    .cangchu {
      margin-top: companyH(10vh);
      font-weight: 500;
      font-size: 22px;
      color: #ffffff;

      font-style: normal;
      text-transform: none;
      background: linear-gradient(to bottom, #7385f5, #fff);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      margin-left: companyW(180vw);
    }
    .cangchuDown {
      margin-top: companyH(10vh);
      background-image: url('../../assets/number-cc/topL.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: companyW(430vw);
      height: companyH(180vh);

      .dowmNEI {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: companyH(20vh);

        .neiText {
          margin-top: companyH(10vh);
          width: 40%;
          height: companyH(70vh);
          color: #fff;
          position: relative;
          top: companyH(20vh);
          left: companyW(20vw);

          .txtT {
            display: flex;
            justify-content: center;
            align-content: center;

            font-weight: 400;
            font-size: 16px;
            color: #839ecb;

            text-align: left;
            font-style: normal;
            text-transform: none;
          }
          .txtD {
            display: flex;
            justify-content: center;
            align-content: center;

            font-size: 20px;
            color: #b5caeb;

            text-align: right;
            font-style: normal;
            text-transform: none;
            background: radial-gradient(to bottom, #030000, #ffffff);
          }
        }
      }
    }
  }
}
</style>
