<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      snTitle="topBgc"
      typeOf="number-cc"
      :title="address + '数字仓储'"
    ></Title>
    <div class="dyr-service-content">
      <div class="dyr-service-left">
        <Power></Power>

        <Service class="dyr-service-service"></Service>
        <LastYear class="dyr-service-service"></LastYear>
      </div>
      <div>
        <div class="dyr-service-middle"></div>


      </div>

      <div class="dyr-service-right">
        <Power1></Power1>
        <Service1 class="dyr-service-service"></Service1>
        <LastYear1 class="dyr-service-service"></LastYear1>
      </div>
    </div>
  </div>
</template>

<script>
import Title from './components/title.vue';
import Power from './power.vue';
import Service from './service.vue';
import LastYear from './lastYear.vue';

import Power1 from './right/power.vue';
import Service1 from './right/service.vue';
import LastYear1 from './right/lastYear.vue';

export default {
  components: {
    Title,
    Power,
    Service,
    LastYear,
    Power1,
    Service1,
    LastYear1
  },
  data () {
    return {
      address: '',
      workload: [ 1, 2, 3, 4 ]
    };
  },
  created () {
    this.address = localStorage.getItem('address');
  },
  mounted () {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dyr-service {
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  // background-image: url(../../assets/drying-page/bcg-img.png);
  background-color: #1d2531;
  background-size: cover;
  background-repeat: no-repeat;

  .dyr-service-title {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
  }

  .dyr-service-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(968vh);
      margin-left: companyW(20vw);

      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }

    .dyr-service-middle {
      position: absolute;
      background-image: url('../../assets/number-cc/bgcbgc.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: companyW(1920vw);
      height: companyH(1080vh);
      top: 0;
      left: 0;
    }

    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);
      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }
  }
}
.middleTop {
  position: absolute;
  top: 13%;
  left: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topFont {
    background-image: url('../../assets/number-cc/fwzl.png');

    width: companyW(113vw);
    height: companyH(60vh);

    font-weight: bold;
    font-size: 24px;
    color: #ffffff;

    text-align: center;
    font-style: normal;
    text-transform: none;
  }

  .topCount {
    width: companyW(44vw);
    height: companyH(52vh);
    background-image: url('../../assets/number-cc/fwzlBGC.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: bold;
    font-size: companyW(44vw);
    color: #f6d368;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 0 companyW(4vw);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
</style>
