<template>
  <div class="dry-service">
    <moduleTitle type="dw" title="仓储环境"></moduleTitle>
    <div class="cangchu">
      <div class="kqwd">
        <div class="left">
          <img
            src="../../../assets/number-cc/cchj1.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="right">
          <div class="wendu">-13%</div>
          <div class="kq">空气温度</div>
        </div>
      </div>
      <div class="kqwd">
        <div class="left">
          <img
            src="../../../assets/number-cc/cchj2.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="right">
          <div class="wendu">2.8</div>
          <div class="kq">空气湿度</div>
        </div>
      </div>
      <div class="kqwd">
        <div class="left">
          <img
            src="../../../assets/number-cc/cchj3.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="right">
          <div class="wendu">59</div>
          <div class="kq">二氧化碳</div>
        </div>
      </div>
      <div class="kqwd">
        <div class="left">
          <img
            src="../../../assets/number-cc/cchj4.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="right">
          <div class="wendu">59312</div>
          <div class="kq">光照强度</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from '../components/moduleTitle.vue';

export default {
  components: {
    moduleTitle
  },
  props: {},
  data () {
    return {
      option: {}
    };
  },
  mounted () {},
  methods: {
    //调用接口
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-service {
  position: relative;
  width: companyW(455vw);
  height: companyH(300vh);
  background: rgba(0, 0, 0, 0.7);
  border: companyW(1vw) solid;
  .cangchu {
    margin-top: companyH(60vh);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .kqwd {
      width: companyW(206vw);
      height: companyH(93vh);
      margin-top: companyH(10vh);
      display: flex;
      align-items: center;

      background: #001424;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #7385f5;

      .left {
        width: companyW(70vw);
        height: companyH(70vh);
        margin-left: companyW(5vw);
      }
      .right {
        margin-left: companyW(30vw);
        .wendu {
          font-weight: 500;
          font-size: 24px;
          color: #7385f5;

          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .kq {
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;

          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
}
</style>
