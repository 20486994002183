<template>
  <div class="dry-lastYear">
    <moduleTitle title="出入库记录"></moduleTitle>
    <div class="dry-lastYear-content">
      <el-table
        stripe
        v-loading="tabLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="TanleContent"
      >
        <el-table-column prop="ck" label="出库/入库" align="center" width="100">
        </el-table-column>

        <el-table-column prop="yj" label="订单业主" align="center">
        </el-table-column>
        <el-table-column prop="num" label="数量（吨）" align="center">
        </el-table-column>
        <el-table-column prop="time" label="时间" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
export default {
  components: {
    moduleTitle
  },
  data () {
    return {
      TanleContent: [
        { ck: '出库', yj: '孙晓华', num: '20', time: '2-20 16:00' }
      ],
      tabLoading: false
    };
  },
  mounted () {},
  methods: {
    //调用接口
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dry-lastYear {
  position: absolute;
  bottom: companyH(19vh);
  width: companyW(455vw);
  height: companyH(322vh);
  background: rgba(0, 0, 0, 0.7);
  border: companyW(1vw) solid;
  /* opacity: 0.74; */
  .dry-lastYear-content {
    margin: 0 auto;
    margin-top: companyH(50vh);
    width: companyW(400vw);
    height: companyH(260vh);
  }
}

::v-deep .el-table th {
  background: #0a121c;

  height: companyH(35vh);

  padding: 0;
  text-align: center;
  border-bottom: #445980 1px solid;

  border-top: #445980 1px solid;
}
::v-deep .cell {
  font-size: companyH(14vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background: #71cef916;

  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  background: #0a121c;
  border-bottom: #445980 1px solid;

  border-top: #445980 1px solid;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}
</style>
