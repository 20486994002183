<template>
  <div class="dry-lastYear">
    <moduleTitle title="能耗统计"></moduleTitle>
    <div class="echars">
      <echarts :option="option"></echarts>
    </div>
  </div>
</template>

<script>
import moduleTitle from '../components/moduleTitle.vue';
import echarts from '@/components/echarts/index.vue';
export default {
  components: {
    moduleTitle,
    echarts
  },
  data () {
    return {
      TanleContent: [
        { ck: '出库', yj: '孙晓华', num: '20', time: '2-20 16:00' }
      ],
      option: {
        title: {},
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [ '最低温度', '最高温度', '平均温度' ],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '9%',
          right: '12%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timedata,
          axisLabel: {
            lineStyle: {
              color: '#BAC6D0', //x轴的颜色
              width: 8 //轴线的宽度
            },
            //修改坐标系字体颜色
            show: true,
            textStyle: {
              color: '#fff'
            }
          }
        },
        color: [ '#FF6535', '#3DC4F6', '#a0687a' ],

        yAxis: {
          type: 'value',
          axisLabel: {
            //修改坐标系字体颜色
            show: true,
            textStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '最低温度',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2
              },
              borderColor: 'rgba(0,0,0,.4)'
            },
            itemStyle: {
              borderColor: '#646ace',
              borderWidth: 0
            },
            tooltip: {
              show: true
            },
            data: [ 20, 22, 21, 28, 26, 30, 24, 22, 21, 40, 35, 38 ]
          },
          {
            name: '最高温度',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2
              },
              borderColor: 'rgba(0,0,0,.4)'
            },
            itemStyle: {
              borderColor: '#646ace',
              borderWidth: 0
            },
            tooltip: {
              show: true
            },
            data: [ 30, 28, 26, 25, 28, 31, 35, 38, 40, 45, 56, 60 ]
          },
          {
            name: '平均温度',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: '#FF6262' // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)'
            },
            itemStyle: {
              color: '#FF6262',
              borderColor: '#646ace',
              borderWidth: 0
            },
            tooltip: {
              show: true
            },
            data: [ 32, 30, 24, 23, 28, 33, 35, 32, 45, 42, 58, 62 ]
          }
        ]
      }
    };
  },
  mounted () {},
  methods: {
    //调用接口
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dry-lastYear {
  position: absolute;
  bottom: companyH(19vh);
  width: companyW(455vw);
  height: companyH(322vh);
  background: rgba(0, 0, 0, 0.7);
  border: companyW(1vw) solid;
  /* opacity: 0.74; */
  .echars {
    margin-top: companyH(40vh);
    height: 80%;
  }
}
</style>
