<template>
  <div class="dry-service">
    <moduleTitle type="dw" title="库存数据"></moduleTitle>
    <div class="dry-service-echart">
      <myCharts :option="option"></myCharts>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
// import Echart from '../../components/echarts/index.vue';
// import option from './config/service.js';

import myCharts from '../../components/echarts/index.vue';

export default {
  components: {
    moduleTitle,
    myCharts
    // Echart
  },
  props: {},
  data () {
    return {
      option: {}
    };
  },
  mounted () {
    this.getData();
  },
  methods: {
    //调用接口

    getData () {
      const chartData = [
        {
          value: 800,
          name: '水果类'
        },
        {
          value: 200,
          name: '蔬菜类'
        },

      ];
      const color = [
        '#975fe4',
        '#45a5ff',
        '#81c926',
        '#7585a2',
        '#fad337',
        '#f2637b',
        '#ff8e49',
        '#37cbcb'
      ];
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      this.option = {
        background: '#fff',
        title: {
          show: true,
          text: '货物总量',
          subtext: `${ sum }吨`,
          x: '20%',
          y: '40%',
          textStyle: {
            color: '#A8C3E6',
            fontSize: 16,
            fontWeight: '400'
          },
          subtextStyle: {
            color: '#DBE7F6',
            fontSize: 24,
            fontWeight: '400'
          },
          textAlign: 'center'
        },
        legend: {
          orient: 'vertical',
          // icon: "diamond",
          icon: 'circle',
          textStyle: {
            color: '#373737',
            fontSize: '12px',
            rich: {
              name: {
                color: '975fe4',
                fontSize: 12,
                width: 60,
                fontWeight: 400,
                padding: [ 0, 0, 0, 2 ]
              },
              symbol: {
                fontSize: 12,
                fontWeight: 400,
                padding: [ 0, 0, 0, 8 ],
                color: '975fe4',
              },
              percent: {
                fontSize: 12,
                fontWeight: 400,
                width: 30,
                padding: [ 0, 0, 0, 4 ],
                color: '975fe4',
              },
              value: {
                color: '975fe4',
                fontSize: 12,
                fontWeight: 400,
                padding: [ 0, 0, 0, 24 ]
              }
            }
          },
          top: 'center',
          right: '0%',
          itemGap: 14,
          itemHeight: 24,
          itemWidth: 24,
          data: chartData.name,
          formatter: function (name) {
            let obj = {};
            if (name) {
              obj = chartData.find(item => item.name === name);
            }
            return `{name|${ name }}{symbol| |}{percent|${
              obj.value && ((obj.value / sum) * 100).toFixed(0)
            }%}{value|${ obj.value || 0 }吨}`;
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            roundCap: true,
            radius: [ '50%', '65%' ],
            center: [ '20%', '50%' ],
            startAngle: 180, //起始角度
            data: chartData,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 10,
              color: function (params) {
                return color[params.dataIndex];
              }
            },
            labelLine: {
              length: 8,
              length2: 16,
              lineStyle: {
                width: 1
              }
            },
            label: {
              show: false,
              fontFamily: 'ysbth',
              position: 'outside',
              padding: [ 0, -4, 0, -4 ],
              formatter (params) {
                if (params.name === '') {
                  return '';
                }
                return `${ params.percent.toFixed(0) }%`;
              },
              color: '#fff',
              fontSize: '14px',
              lineHeight: 10
            }
          }
        ]
      };
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-service {
  position: relative;
  width: companyW(455vw);
  height: companyH(322vh);
  background: rgba(0, 0, 0, 0.7);
  border: companyW(1vw) solid;

  /* opacity: 0.74; */
  .dry-service-echart {
    margin: 0 auto;
    margin-top: companyH(40vh);
    width: companyW(400vw);
    height: companyH(260vh);
  }
}
</style>
