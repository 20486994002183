<template>
  <!-- <div class="module-title"> -->
  <!-- <div class="module-title-desc">
     </div> -->

  <div class="boxTitle">
    <div class="text">{{ title }}</div>
  </div>

  <!-- </div> -->
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      require: true
    }
  },
  data () {
    return {};
  },
  watch: {},
  methods: {},
  mounted () {}
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}
.boxTitle {
  position: absolute;
  width: 95%;
  height: companyH(35vh);

  display: flex;
  justify-content: space-between;
  padding: companyH(6vh) companyW(10vw) companyH(0) companyW(30vw);

  .text {
    font-size: companyH(20vh);
    font-weight: bold;
    color: #ffffff;
    height: companyH(25vh);
    line-height: companyH(25vh);
    padding-left: companyW(10vw);
  }

  background-image: url('../../../assets/number-cc/title.png');
  width: companyW(417vw);
  height: companyH(38vh);
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
